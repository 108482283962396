import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../../molecules/FormControl/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { applicantSelector } from '../../../../redux/features/applicant/ApplicantSlice';
import { ExhibitionDetailed, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';

// Validation schema using Yup
const validationSchema = Yup.object({
  registration_details: Yup.string().required('Registration Details is required'),
  event_name: Yup.string().required('Event Name is required'),
  event_purpose: Yup.string().required('Purpose is required'),
  category: Yup.string().required('Category is required'),
});

const ExhibitionDetails = ({data}) => {
  const {
    isFetchingApplicantAdd,
    isSuccessReqApplicantAdd,
    isErrorApplicantAdd,
    errorMessageAdd,
    isSuccessResApplicantAdd,
    isFetchingApplicantGet,
    isSuccessResApplicantGet,
    applicantData,
    isSuccessResStateCity,
    StateCityData,
    isSuccessResState,
    StateData,
    isSuccessResDistrict,
    DistrictData,
  } = useSelector(applicantSelector);
  const { isFetchingBidder,
    isSuccessBidder,isFetchingExhibition,isSuccessExhibition } = useSelector(PmayNonPmaySelector)


    const dispatch = useDispatch();
    useEffect(() => {
      if (isSuccessExhibition) {
          dispatch(RegistrationStepperSave("4"))
      }
  }, [isSuccessExhibition]);
  const options = [
    { value: '1', label: "Any Organizations/Institution " },
    { value: '2', label: 'Government Organizations/Institutes' },
  ];
  
  // Map full labels for tooltips
  const fullLabels = {
    '1': "Any Organizations /Institution (All Institutes / Organizations except Govt.Offices/Organization) for any program but excluding Circus. Government - Semi Government, Public Organizations (for non commercial purpose)",
    '2': 'Government Organizations/Institutes'
  };

  return (
    <Formik
      initialValues={{
        registration_details: data?.registration_details||'',
        event_name: data?.event_name || '',
        event_purpose: data?.event_purpose||'',
        category: data?.category || '',
       
      

      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        // Handle form submission
        console.log(values);
        setSubmitting(false);
        dispatch(ExhibitionDetailed(values));
      }}

      
    >

      
      {({ submitForm, isSubmitting,values }) => (
        <Form>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control="input"
                  variant="outlined"
                  label="Registration Details"
                  placeholder="Registration Details"
                  name="registration_details"
                  type="text"
                  id="registration_details"
                  required
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control="input"
                  variant="outlined"
                  label="Event Name"
                  placeholder="Event Name"
                  name="event_name"
                  type="text"
                  id="event_name"
                  required
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control="input"
                  variant="outlined"
                  label="Purpose"
                  placeholder="Purpose"
                  name="event_purpose"
                  type="text"
                  id="event_purpose"
                  required
                  margin="dense"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Tooltip 
                  title={fullLabels[values.category] || ''}
                  arrow
                >
                  <div>
                    <FormControl
                      control="selectbox"
                      variant="outlined"
                      name="category"
                      label="Category"
                      options={options}
                      margin='dense'
                      required
                      // Custom class for dropdown
                      className="custom-selectbox"
                      renderValue={(selected) => {
                        const selectedOption = options.find(option => option.value === selected);
                        return (
                          <div>
                            {selectedOption?.label}
                          </div>
                        );
                      }}
                    />
                  </div>
                </Tooltip>
              </Grid>
              {/* <Grid item xs={12} sm={6}> */}
                {/* <FormControl
                  control="input"
                  variant="outlined"
                  label="Category"
                  placeholder="Category"
                  name="category"
                  type="text"
                  id="category"
                  required
                  margin="dense"
                /> */}
                 {/* <FormControl
                        control="selectbox"
                        variant="outlined"
                        name="category"
                        label="Category"
                        options={options}
                        margin='dense'
                        required
                      /> */}
                      
              {/* </Grid> */}
            </Grid>
            <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isFetchingExhibition}
        >
          {isFetchingExhibition && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
          {isFetchingExhibition ? 'Saving...' : 'Save & Continue'}
        </Button>
        </Grid>
          </Box>
          <style>
            {`
              .custom-selectbox .MuiSelect-select {
                max-height: 200px; /* Set max height for dropdown */
                overflow-y: auto; /* Add scrollbar if needed */
              }
            `}
          </style>
        </Form>
      )}
    </Formik>
  );
};

export default ExhibitionDetails;
