// import React, { useEffect, useState } from 'react';
// import { withStyles, makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
// import { Box } from '@material-ui/core';
// import { useDispatch, useSelector } from 'react-redux';
// import { EventPeriod, getSummaryDetails, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
// import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);

// const useStyles = makeStyles({
//   table: {
//     minWidth: 700,
//   },
//   smallColumn: {
//     maxWidth: 120,
//   },
// });

// function PeriodDetails({data}) {
//   const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
//   const [summary, setSummary] = useState({});
//   const classes = useStyles();
//   // const [formData, setFormData] = useState({
//   //   periods: [
//   //     { id: 1, purpose: 'Set up', from: '', to: '', days: '', area: '', selected: true },
//   //     { id: 2, purpose: 'Exhibition / Event', from: '', to: '', days: '', area: '', selected: true },
//   //     { id: 3, purpose: 'Parking', from: '', to: '', days: '', area: '', selected: true },
//   //     { id: 4, purpose: 'Dismantle', from: '', to: '', days: '', area: '', selected: true },
//   //   ],
//   // });
//   useEffect(() => {
   
//     dispatch(getSummaryDetails());
//   }, [])

//   useEffect(() => {
//     if (isSuccessResSummary) {
//         setSummary(summaryData[0]);
      
//     }
// }, [isSuccessResSummary, summaryData]);
// // data?.EventPeriod && JSON.parse(data?.EventPeriod)
// // summaryData[0]?.period_of_exhibition ? summaryData[0]?.period_of_exhibition && JSON.parse(summaryData[0]?.period_of_exhibition) :
//   const initialData =  [
//     { id: 1, purpose: 'Set up', from: '', to: '', days: '', area: '', selected: true },
//     { id: 2, purpose: 'Exhibition / Event', from: '', to: '', days: '', area: '', selected: true },
//     { id: 3, purpose: 'Parking', from: '', to: '', days: '', area: '', selected: true },
//     { id: 4, purpose: 'Dismantle', from: '', to: '', days: '', area: '', selected: true },
//   ];
//   const [formData, setFormData] = useState({ period_of_exhibition: initialData });
//   const {  isSuccessEventPeriod , isFetchingEventPeriod} = useSelector(PmayNonPmaySelector)


//     const dispatch = useDispatch();
//     useEffect(() => {
//       if ( isSuccessEventPeriod) {
//         dispatch(getSummaryDetails());
//           dispatch(RegistrationStepperSave("5"))
//       }
//   }, [ isSuccessEventPeriod]);
//   const calculateDays = (from, to) => {
//     if (!from || !to) return '';
//     const fromDate = new Date(from);
//     const toDate = new Date(to);
//     const timeDiff = toDate - fromDate;
//     const daysDiff = timeDiff / (1000 * 60 * 60 * 24) + 1; // Adding 1 to include both start and end date
//     return daysDiff > 0 ? daysDiff : '';
//   };

//   const handleChange = (id, field, value) => {
//     const updatedPeriods = formData.period_of_exhibition.map((period) => {
//       if (period.id === id) {
//         const updatedPeriod = { ...period, [field]: value };
//         if (field === 'from' || field === 'to') {
//           updatedPeriod.days = calculateDays(updatedPeriod.from, updatedPeriod.to);
//         }
//         return updatedPeriod;
//       }
//       return period;
//     });
//     setFormData({ period_of_exhibition: updatedPeriods });
//   };

//   const handleCheckboxChange = (id) => {
//     const updatedPeriods = formData.period_of_exhibition.map((period) =>
//       period.id === id ? { ...period, selected: !period.selected } : period
//     );
//     setFormData({ period_of_exhibition: updatedPeriods });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     // Handle form submission
//     console.log(formData);
//     dispatch(EventPeriod(formData));
//   };

//   return (
//     <div className="App">
//       <form onSubmit={handleSubmit}>
//         <TableContainer component={Paper}>
//           <Table className={classes.table} aria-label="customized table">
//             <TableHead>
//               <TableRow>
//                 <StyledTableCell>Select</StyledTableCell>
//                 <StyledTableCell>Purpose</StyledTableCell>
//                 <StyledTableCell>From</StyledTableCell>
//                 <StyledTableCell>To</StyledTableCell>
//                 <StyledTableCell>No. of Days</StyledTableCell>
//                 <StyledTableCell>Area (Sq.mt.)</StyledTableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {formData.period_of_exhibition.map((period) => (
//                 <StyledTableRow key={period.id}>
//                   <StyledTableCell>
//                     <Checkbox
//                       checked={period.selected}
//                       onChange={() => handleCheckboxChange(period.id)}
//                     />
//                   </StyledTableCell>
//                   <StyledTableCell>{period.purpose}</StyledTableCell>
//                   <StyledTableCell>
//                     <TextField
//                       type="date"
//                       value={period.from}
//                       onChange={(e) => handleChange(period.id, 'from', e.target.value)}
//                       disabled={!period.selected}
//                       className={classes.smallColumn}
//                     />
//                   </StyledTableCell>
//                   <StyledTableCell>
//                     <TextField
//                       type="date"
//                       value={period.to}
//                       onChange={(e) => handleChange(period.id, 'to', e.target.value)}
//                       disabled={!period.selected}
//                       className={classes.smallColumn}
//                     />
//                   </StyledTableCell>
//                   <StyledTableCell>
//                     <TextField
//                       type="number"
//                       value={period.days}
//                       readOnly
//                       className={classes.smallColumn}
//                     />
//                   </StyledTableCell>
//                   <StyledTableCell>
//                     <TextField
//                       type="number"
//                       value={period.area}
//                       onChange={(e) => handleChange(period.id, 'area', e.target.value)}
//                       disabled={!period.selected}
//                       className={classes.smallColumn}
//                     />
//                   </StyledTableCell>
//                 </StyledTableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <Box mt={2}><Button type="submit" variant="contained" color="primary">
//           Submit
//         </Button></Box>
//       </form>
//     </div>
//   );
// }

// export default PeriodDetails;


import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { EventPeriod, getSummaryDetails, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
import { CircularProgress, Grid } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  height:10
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  smallColumn: {
    maxWidth: 130,
  },

});

function PeriodDetails({ data }) {
  const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
  const [summary, setSummary] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSummaryDetails());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccessResSummary) {
      setSummary(summaryData[0]);
    }
  }, [isSuccessResSummary, summaryData]);

  // const initialData =  summaryData[0]?.period_of_exhibition ?  JSON.parse(summaryData[0]?.period_of_exhibition) : JSON.parse(data?.period_of_exhibition || [
  //        { id: 1, purpose: 'Set up', from: '', to: '', days: '', area: '', selected: true },
  //        { id: 2, purpose: 'Exhibition / Event', from: '', to: '', days: '', area: '', selected: true },
  //        { id: 3, purpose: 'Parking', from: '', to: '', days: '', area: '', selected: true },
  //        { id: 4, purpose: 'Dismantle', from: '', to: '', days: '', area: '', selected: true },
  //      ]);

  const defaultData = [
    { id: 1, purpose: 'Set up', from: '', to: '', days: '', area: '', selected: true },
    { id: 2, purpose: 'Exhibition / Event', from: '', to: '', days: '', area: '', selected: true },
    { id: 3, purpose: 'Parking', from: '', to: '', days: '', area: '', selected: true },
    { id: 4, purpose: 'Dismantle', from: '', to: '', days: '', area: '', selected: true },
  ];

  const initialData = summaryData[0]?.period_of_exhibition 
    ? JSON.parse(summaryData[0]?.period_of_exhibition) 
    : JSON.parse(data?.period_of_exhibition || JSON.stringify(defaultData));


  const [formData, setFormData] = useState({ period_of_exhibition: initialData });

  const { isSuccessEventPeriod, isFetchingEventPeriod } = useSelector(PmayNonPmaySelector);

  useEffect(() => {
    if (isSuccessEventPeriod) {
      dispatch(getSummaryDetails());
      dispatch(RegistrationStepperSave('5'));
    }
  }, [dispatch, isSuccessEventPeriod]);

  const calculateDays = (from, to) => {
    if (!from || !to) return '';
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const timeDiff = toDate - fromDate;
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24) + 1; // Adding 1 to include both start and end date
    return daysDiff > 0 ? daysDiff : '';
  };

  // const handleChange = (id, field, value) => {
  //   const updatedPeriods = formData.period_of_exhibition.map((period) => {
  //     if (period.id === id) {
  //       const updatedPeriod = { ...period, [field]: value };
  //       if (field === 'from' || field === 'to') {
  //         updatedPeriod.days = calculateDays(updatedPeriod.from, updatedPeriod.to);
  //       }
  //       return updatedPeriod;
  //     }
  //     return period;
  //   });
  //   setFormData({ period_of_exhibition: updatedPeriods });
  // };

  // const handleCheckboxChange = (id) => {
  //   const updatedPeriods = formData.period_of_exhibition.map((period) =>
  //     period.id === id ? { ...period, selected: !period.selected } : period
  //   );
  //   setFormData({ period_of_exhibition: updatedPeriods });
  // };
  const handleChange = (id, field, value) => {
    const updatedPeriods = formData.period_of_exhibition.map((period) => {
      if (period.id === id) {
        const updatedPeriod = { ...period, [field]: value };
        if (field === 'from' || field === 'to') {
          updatedPeriod.days = calculateDays(updatedPeriod.from, updatedPeriod.to);
        }
        if (field === 'area' && value.length > 6) {
          return period;
        }
        return updatedPeriod;
      }
      return period;
    });
    setFormData({ period_of_exhibition: updatedPeriods });
  };

  const handleCheckboxChange = (id) => {
    const updatedPeriods = formData.period_of_exhibition.map((period) =>
      period.id === id ? { ...period, selected: !period.selected } : period
    );
    setFormData({ period_of_exhibition: updatedPeriods });
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    dispatch(EventPeriod(formData));
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Select</StyledTableCell>
                <StyledTableCell>Purpose</StyledTableCell>
                <StyledTableCell>From</StyledTableCell>
                <StyledTableCell>To</StyledTableCell>
                <StyledTableCell>No. of Days</StyledTableCell>
                <StyledTableCell>Area (Sq.mt.)</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.period_of_exhibition.map((period) => (
                <StyledTableRow key={period.id}>
                  <StyledTableCell>
                    <Checkbox
                      checked={period.selected}
                      onChange={() => handleCheckboxChange(period.id)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{period.purpose}</StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      type="date"
                      value={period.from}
                      onChange={(e) => handleChange(period.id, 'from', e.target.value)}
                      disabled={!period.selected}
                      className={classes.smallColumn}
                      inputProps={{ min: new Date().toISOString().split('T')[0] }} 
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      type="date"
                      value={period.to}
                      onChange={(e) => handleChange(period.id, 'to', e.target.value)}
                      disabled={!period.selected}
                      className={classes.smallColumn}
                      inputProps={{ min: new Date().toISOString().split('T')[0] }} 
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      type="number"
                      value={period.days}
                      readOnly
                      inputProps={{ maxLength: 3 }}
                      className={classes.smallColumn}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      type="number"
                      value={period.area}
                      onChange={(e) => handleChange(period.id, 'area', e.target.value)}
                      disabled={!period.selected}
                      inputProps={{ maxLength: 6 }}
                      className={classes.smallColumn}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isFetchingEventPeriod}
        >
          {isFetchingEventPeriod && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
          {isFetchingEventPeriod ? 'Saving...' : 'Save & Continue'}
        </Button>
        </Grid>
      </form>
    </div>
  );
}

export default PeriodDetails;
