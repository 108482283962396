import React, { useEffect, useState } from 'react';
import { Grid, Button, CircularProgress, TextField, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  applicantSelector,
  getDistrict,
  getState,
  getstaeCityByPinCode
} from '../../../../redux/features/applicant/ApplicantSlice';
import {
  clearBidderState,
  getSummaryDetails,
  PmayNonPmaySelector,
  RegisterBidderType
} from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';

// Validation schema for Formik
const validationSchema = Yup.object().shape({
  organization_name: Yup.string().required('Organization Name is required'),
  organization_address: Yup.string().required('Address is required'),
  organization_district: Yup.string().required('District is required'),
  organization_city: Yup.string().required('City is required'),
  organization_pincode: Yup.string()
    .matches(/^\d{6}$/, 'Pincode must be exactly 6 digits')
    .required('Pincode is required'),
  organization_state: Yup.string().required('State is required'),
  organization_country: Yup.string().required('Country is required'),
  organization_mobile: Yup.string()
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits')
    .required('Mobile Number is required'),
  organization_fax: Yup.string()
    .matches(/^\d{10}$/, 'Fax Number must be exactly 10 digits')
    .required('Fax Number is required')
});

const OrganizationDetails = ({ data }) => {
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('India');
  const [countries] = useState([{ value: "India", label: "India" }]);

  const { isFetchingBidder, isSuccessBidder } = useSelector(PmayNonPmaySelector);
  const { isSuccessResStateCity, StateCityData, isSuccessResState, StateData, isSuccessResDistrict, DistrictData } = useSelector(applicantSelector);
  const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
  
  
  
  useEffect(() => {
    dispatch(getSummaryDetails());
    dispatch(getState());
    dispatch(getDistrict());
  }, []);
  
  
  const getInitialValue = (field) => {
    return summaryData ? summaryData[0]?.[field] : data?.[field] || '';
  };

  const initialValues = {
    
    organization_name: getInitialValue('organization_name'),
      organization_address: getInitialValue('organization_address'),
      organization_mobile: getInitialValue('organization_mobile'),
      organization_fax: getInitialValue('organization_fax'),
      organization_country:summaryData ? summaryData[0]?.organization_country : data?.organization_country || 'India',
      organization_state: getInitialValue('organization_state'),
      organization_city: getInitialValue('organization_city'),
      organization_pincode: getInitialValue('organization_pincode'),
      organization_district: getInitialValue('organization_district'),
  };

  const formik = useFormik({
    initialValues: initialValues,
    // enableReinitialize: true, 
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      dispatch(RegisterBidderType(values));
    }
  });

  const autoCompletePincode = (event) => {
    const pin_code = event.target.value;
    if (pin_code.length === 6) {
      dispatch(getstaeCityByPinCode(pin_code));
    }
  };



  useEffect(() => {
    if (isSuccessBidder) {
      dispatch(RegistrationStepperSave("2"));
    }
  }, [isSuccessBidder, dispatch]);

  useEffect(() => {
    if (isSuccessResState && StateData) {
      setStateList(StateData.map(element => ({ value: element.State, label: element.State })));
    }
  }, [isSuccessResState, StateData]);

  useEffect(() => {
    if (isSuccessResDistrict && DistrictData) {
      setDistrictList(DistrictData.map(item => ({ value: item.District, label: item.District })));
    }
  }, [isSuccessResDistrict, DistrictData]);

  useEffect(() => {
    if (isSuccessResStateCity && StateCityData) {
      formik.setValues(prevValues => ({
        ...prevValues,
        organization_city: StateCityData.City || prevValues.organization_city,
        organization_state: StateCityData.State || prevValues.organization_state,
        organization_district: StateCityData.District || prevValues.organization_district,
        organization_pincode: StateCityData.PinCode || prevValues.organization_pincode,
      }));
    }
  }, [isSuccessResStateCity, StateCityData, formik.setValues]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
         
          
            <TextField
              id="organization_name"
              name="organization_name"
              label="Organization Name"
              fullWidth
              variant="outlined"
              value={formik.values.organization_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin='dense'
              required
              error={formik.touched.organization_name && Boolean(formik.errors.organization_name)}
              helperText={formik.touched.organization_name && formik.errors.organization_name}
            />
        
        </Grid>
        <Grid item xs={6}>
        
    
            <TextField
              id="organization_address"
              name="organization_address"
              label="Address"
              variant="outlined"
              fullWidth
              value={formik.values.organization_address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin='dense'
              required
              error={formik.touched.organization_address && Boolean(formik.errors.organization_address)}
              helperText={formik.touched.organization_address && formik.errors.organization_address}
            />
        
        </Grid>
        <Grid item xs={3}>
        
          
            <TextField
              id="organization_district"
              name="organization_district"
              select
              label="District"
              variant="outlined"
              fullWidth
              required
              value={formik.values.organization_district}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin='dense'
              error={formik.touched.organization_district && Boolean(formik.errors.organization_district)}
              helperText={formik.touched.organization_district && formik.errors.organization_district}
            >
              {districtList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
         
        </Grid>
        <Grid item xs={3}>
         
       
            <TextField
              id="organization_city"
              name="organization_city"
              label="City"
              required
              variant="outlined"
              margin='dense'
              fullWidth
              value={formik.values.organization_city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.organization_city && Boolean(formik.errors.organization_city)}
              helperText={formik.touched.organization_city && formik.errors.organization_city}
            />
        
        </Grid>
        <Grid item xs={6}>
        
            <TextField
              id="organization_pincode"
              name="organization_pincode"
              label="Pincode"
              variant="outlined"
              type="number"
              margin='dense'
              fullWidth
              required
              value={formik.values.organization_pincode}
              onChange={(e) => {
                autoCompletePincode(e);
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.organization_pincode && Boolean(formik.errors.organization_pincode)}
              helperText={formik.touched.organization_pincode && formik.errors.organization_pincode}
              inputProps={{ maxLength: 6 }}
            />
          
        </Grid>
        <Grid item xs={6}>
        
         
            <TextField
              id="organization_state"
              name="organization_state"
              select
              label="State"
              variant="outlined"
              fullWidth
              value={formik.values.organization_state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin='dense'
              required
              error={formik.touched.organization_state && Boolean(formik.errors.organization_state)}
              helperText={formik.touched.organization_state && formik.errors.organization_state}
            >
              {stateList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        
        </Grid>
        <Grid item xs={6}>
       
            <TextField
              id="organization_country"
              name="organization_country"
              select
              label="Country"
              fullWidth
              variant="outlined"
              value={formik.values.organization_country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin='dense'
              required
              error={formik.touched.organization_country && Boolean(formik.errors.organization_country)}
              helperText={formik.touched.organization_country && formik.errors.organization_country}
            >
              {countries.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        
        </Grid>
        <Grid item xs={6}>
        
           
            <TextField
              id="organization_mobile"
              name="organization_mobile"
              label="Mobile"
              variant="outlined"
              type="numeric"
              margin='dense'
              fullWidth
              required
              value={formik.values.organization_mobile}
              inputProps={{
                maxLength: 10,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.organization_mobile && Boolean(formik.errors.organization_mobile)}
              helperText={formik.touched.organization_mobile && formik.errors.organization_mobile}
            />
      
        </Grid>
        <Grid item xs={6}>
         
           
            <TextField
              id="organization_fax"
              name="organization_fax"
              label="Fax"
              variant="outlined"
              type="numeric"
              fullWidth
              margin='dense'
              value={formik.values.organization_fax}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{
                maxLength: 10,
              }}
              error={formik.touched.organization_fax && Boolean(formik.errors.organization_fax)}
              helperText={formik.touched.organization_fax && formik.errors.organization_fax}
            />
        
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default OrganizationDetails;
