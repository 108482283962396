import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { jsPDF } from 'jspdf';
import mmrda from '../../../../assets/mmrda.png'; // Replace with the path to your logo
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { Paymentformapi, PmayNonPmaySelector, clearPaymentDetails, getPaymentDetail, getSummaryDetails } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';

const PaymentForm = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    amount: '15000.00',
  });

  const [open, setOpen] = useState(false);
  const [transId, setTransId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(null);
  const { isFetchingPaymentForm, isSuccessPaymentForm, paymentData, isSuccessPaymentDetail } = useSelector(PmayNonPmaySelector)
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'cardNumber') {
      const formattedValue = value
        .replace(/\D/g, '')
        .replace(/(.{4})/g, '$1-')
        .slice(0, 19);
      setFormData({
        ...formData,
        [name]: formattedValue,
      });
    } else if (name === 'expiryDate') {
      const formattedValue = value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d{1,2})/, '$1/$2')
        .slice(0, 5);
      setFormData({
        ...formData,
        [name]: formattedValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();



    const submissionData = {
      ...formData,
      paidBy: 'Card',
    }
    console.log("Form Data:", submissionData);
    dispatch(Paymentformapi(submissionData));
  };





  useEffect(() => {
    if (isSuccessPaymentForm && paymentData) {
      setOpen(true);

      setTransId(paymentData?.TransId)
    }
  }, [isSuccessPaymentForm]);
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add the logo
    const imgData = mmrda; // Base64 encoded image or imported image file
    doc.addImage(imgData, 'PNG', 10, 10, 50, 20); // Position the image

    // Add text and lines to mimic the layout
    doc.setFontSize(14);

    // Header
    doc.text('Application Fees Receipt', 105, 20, null, null, 'center');
    doc.setFontSize(10);
    doc.text(`Receipt No : ${Math.floor(Math.random() * 10000000000000)}`, 150, 30, null, null, 'right');
    doc.text(`Transaction Id : TXN${Math.random().toString(36).substring(2).toUpperCase()}`, 150, 40, null, null, 'right');

    // Application details
    doc.text(`Application No.: 2410000118`, 20, 50);
    doc.text('Applicant Id', 20, 60);
    doc.text('118', 70, 60);

    doc.text('Applicant Name', 20, 70);
    doc.text(`${formData.name}`, 70, 70);

    doc.text('Paid By', 20, 80);
    doc.text('Paid By Card', 70, 80);

    doc.text('Total Paid Amount', 20, 90);
    doc.text(`₹ ${formData.amount}`, 70, 90);

    doc.text(`Paid At ${new Date().toLocaleString()}`, 20, 100);

    // Payment summary
    doc.text('Payment Summary', 20, 120);
    doc.text(`Application Fee : ₹ ${formData.amount}`, 20, 130);
    doc.text('GST(0%) : ₹ 0.00', 20, 140);
    doc.text('Total Amount :', 20, 150);
    doc.text(`₹ ${formData.amount}`, 70, 150);

    doc.save('receipt.pdf');
  };

  const handleClose = (status) => {
    setOpen(false);
    dispatch(clearPaymentDetails());
    setPaymentStatus(status);
    if (status === 'success') {
      const data = { transId: transId }
      dispatch(getPaymentDetail(data))


    }
  };
  useEffect(() => {
    if (isSuccessPaymentDetail) {
      history.push("/dashboard");
      dispatch(clearPaymentDetails());
      dispatch(RegistrationStepperSave("7"))
      // generatePDF();
    }

  }, [isSuccessPaymentDetail])
  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            align="center"
            sx={{ fontWeight: 'bold', mb: 1, background: "linear-gradient(126deg, #00a74a, #15489f)", color: 'white' }}
          >
            Payment Form
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                fullWidth
                label="Name on Card"
                name="name"
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
                required
                margin='dense'
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                label="Card Number"
                name="cardNumber"
                variant="outlined"
                value={formData.cardNumber}
                onChange={handleChange}
                required
                inputProps={{ maxLength: 19 }}
                helperText="Card number must be 16 digits"
                margin='dense'
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Expiry Date (MM/YY)"
                  name="expiryDate"
                  variant="outlined"
                  value={formData.expiryDate}
                  onChange={handleChange}
                  required
                  inputProps={{ maxLength: 5 }}
                  helperText="Format: MM/YY"
                  margin='dense'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="CVV"
                  name="cvv"
                  variant="outlined"
                  value={formData.cvv}
                  onChange={handleChange}
                  required
                  inputProps={{ maxLength: 3, pattern: "\\d{3}" }}
                  helperText="CVV must be 3 digits"
                  margin='dense'
                />
              </Grid>
            </Grid>
            <Box mb={2} mt={2}>
              <TextField
                fullWidth
                label="Amount (Rs)"
                name="amount"
                variant="outlined"
                value={formData.amount}
                onChange={handleChange}
                required
                type="number"
                inputProps={{ min: "0.01", step: "0.01" }}
                margin='dense'
              />
            </Box>
            <Button type="submit" variant="contained" color="primary">
              Pay Now
            </Button>
          </form>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={() => handleClose('cancel')}>
        <DialogTitle>Payment Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please choose the payment outcome.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose('success')} color="primary">
            Success
          </Button>
          <Button onClick={() => handleClose('failure')} color="secondary">
            Failure
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PaymentForm;
