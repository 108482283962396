import React from "react";

import ManagerEarningsSummary from "../../organisms/MngrEarningsSummaryPageComponents/ManagerEarningsSummary/ManagerEarningsSummary";

const ManagerEarningsSummaryPage = () => {
  return (
    // <AgentLayout isStepper={false}>
      <ManagerEarningsSummary />
    // </AgentLayout>
  );
};

export default ManagerEarningsSummaryPage;
