import React from "react";

import ManagerDashboard from "../../organisms/ManagerDashboardPageComponents/ManagerDashboard/ManagerDashboard";

const ManagerDashboardPage = () => {
  return (
   
      <ManagerDashboard />
    // </AgentLayout>
  );
};

export default ManagerDashboardPage;
