import React, { useEffect, useState } from 'react';
import { Box, Popover, Card, CardContent, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Divider } from '@mui/material';
import HorizontalLinearStepper from '../Stepper/HorizontalLinearStepper';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import barcode from '../../../../assets/barcode.png';
import { useDispatch, useSelector } from 'react-redux';
import { PmayNonPmaySelector, getSummaryDetails } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import moment from 'moment/moment';
import mmrda from '../../../../assets/mmrda.png';
import jsPDF from 'jspdf';
import { ApiEndPoint } from '../../../../utils/Common';
import { docDeclarationSelector, getUploadDocumentsList } from '../../../../redux/features/file/DocDeclarationSlice';
import { Alert, AlertTitle } from '@material-ui/lab';
import Loading from '../../../atoms/Loading/Loading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getRegistrationProgress } from '../../../../redux/features/registration/registrationStepperSlice';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: 'white !important',
        fontWeight: 'bold !important'
    },
    body: {
        fontSize: 10,
        padding: '0px',
        margin: '0px'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const SummaryDetails = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
    const [summary, setSummary] = useState({});
    const [payment, setPayment] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [verifiedDocs, setVerifiedDocs] = useState([]);
  const [rejectedDocs, setRejectedDocs] = useState([]);
  const [pendingDocs, setPendingDocs] = useState([]);
  const {
    isFetchingGetUploadList,
    isSuccessResUploadList,
    isErrorGetUploadList,
    errorMsgGetUploadList,
    getUploadListData,
  } = useSelector(docDeclarationSelector);
    const ApiEndPointPdf = ApiEndPoint + "/MortgageNoc/exhibitionOverviewPdf/";
    
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    useEffect(() => {
        dispatch(getRegistrationProgress())
        dispatch(getSummaryDetails());
        let sendData = {
            ApplicantId: localStorage.getItem("applicantId"),
            Lang: localStorage.getItem("i18nextLng"),
          };
          dispatch(getUploadDocumentsList(sendData));
    }, [dispatch]);

    useEffect(() => {
        if (isSuccessResSummary) {
            setSummary(summaryData[0]);
            setPayment(summaryData?.PaymentDetails[0]); // Assuming summaryData is an array
        }
    }, [isSuccessResSummary, summaryData]);
    console.log(payment, "dsfdsd")
    const useStyles = makeStyles({
        table: {
            minWidth: 700,
        },
        smallColumn: {
            maxWidth: 100,
        },
    });
    const classes = useStyles();
    const options = [
        {
            value: '1', label: "Any Organizations /Institution (All Institutes / Organizations except Govt.Offices/Organization) for any program but excluding Circus. Government - Semi Government, Public Organizations (for non commercial purpose) "},
        { value: '2', label: 'Government Organizations/Institutes' },

    ];
    const selectedOption = summary?.ApplicantCatId === '1' ? options[0].label : options[1].label;

    const getPeriodDates = (eventPeriod) => {
        try {
            const periods = JSON.parse(eventPeriod);
            const fromDates = periods?.map(period => new Date(period.from));
            const toDates = periods?.map(period => new Date(period.to));
            const minFromDate = new Date(Math.min(...fromDates)).toISOString().split('T')[0];
            const maxToDate = new Date(Math.max(...toDates)).toISOString().split('T')[0];
            return { minFromDate, maxToDate };
        } catch (error) {
            console.error('Error parsing eventPeriod:', error);
            return { minFromDate: '', maxToDate: '' };
        }
    };

    const periodDates = summary?.period_of_exhibition    ? getPeriodDates(summary.period_of_exhibition    ) : { minFromDate: '', maxToDate: '' };

    const downloadPdf = () => {
        setPdfLoading(true);
        let fileUrl = ApiEndPointPdf +
        summaryData[0]?.ExhibitionApplicationId

        fetch(fileUrl, {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("jwtToken"),
          },
        }).then((response) => response.blob()).then((blob) => {
          setPdfLoading(false);
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          let today = new Date();
          const dateTime = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DD-h:mm');
          link.download = `${localStorage.getItem("applicantId")} -${dateTime}`;
          document.body.append(link);
          link.click();
          link.remove();
          // in case the Blob uses a lot of memory
          setTimeout(() => URL.revokeObjectURL(link.href), 300);
        }).catch(function (error) {
          setPdfLoading(false);
        });
      };
      useEffect(() => {
        if (getUploadListData.DocumentDetails) {
          const verified = [];
          const rejected = [];
          const pending = [];
    
          getUploadListData.DocumentDetails.forEach((doc) => {
            switch (doc.Verified) {
              case 1:
                verified.push(doc);
                break;
              case 2:
                if(doc.IsResubmitted == 0){
                rejected.push(doc);
                }
                break;
              default:
                pending.push(doc);
                break;
            }
          });
    
          setVerifiedDocs(verified);
          setRejectedDocs(rejected);
          setPendingDocs(pending);
        }
      }, [getUploadListData]);
    
    
      console.log(verifiedDocs, rejectedDocs, pendingDocs);
    return (
        <Card sx={{ maxWidth: 900, margin: 'auto', mt: 4 }}>
              {(pdfLoading || isFetchingGetUploadList) && (
        <Loading isOpen={pdfLoading || isFetchingGetUploadList} />
      )}
        
            <CardContent>
            {/* {pendingDocs.length > 0 && rejectedDocs.length === 0 && verifiedDocs.length === 0 && (
          <Alert severity="warning" style={{ marginBottom: 12 }}>
            <AlertTitle>Document Verification in Progress</AlertTitle>
            Your Document Verification is UnderProcess.
          </Alert>
        )} */}

        {rejectedDocs.length > 0 && (
          <Alert severity="error" style={{ marginBottom: 12 }} action={
            <Button color="inherit" variant="outlined" size="small" onClick={() => history.push("/registration?step=6")}>
              Re-Upload
            </Button>}>
            <AlertTitle>Document Rejected</AlertTitle>
            <ul>
              {rejectedDocs.map((doc) => (
                <li key={doc.DocumentId}>
                  {doc.DocumentName}: {doc.VerifiedReason || 'Verification failed. Please'}{" "}<span style={{ fontWeight: 600, cursor: "pointer", textDecoration: "underline" }} onClick={() => history.push(`/registration?step=9&docId=${doc.DocumentId}`)}>Re-Upload</span>
                </li>
              ))}
            </ul>
          </Alert>
        )}

        {/* {verifiedDocs.length === getUploadListData?.DocumentDetails?.length && (
          <Alert severity="success" style={{ marginBottom: 12 }}>
            <AlertTitle>All Documents Verified</AlertTitle>
            All documents have been successfully verified.
          </Alert>
        )} */}
        {summaryData[0]?.VerificationStatus == "1" && (
          <Alert severity="success" style={{ marginBottom: 12 }}>
            <AlertTitle>All Documents Verified</AlertTitle>
            All documents have been successfully verified.
          </Alert>
        )}
         {summaryData[0]?.VerificationStatus === "0" && (
          <Alert severity="warning" style={{ marginBottom: 12 }}>
          <AlertTitle>Document Verification in Progress</AlertTitle>
          Your Document Verification is UnderProcess.
        </Alert>
        )}
       {/* { console.log(summaryData[0]?.VerificationStatus)}
        
        */}
                <Typography variant="h6" component="div" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 1, background: "linear-gradient(126deg, #00a74a, #15489f)", color: 'white' }}>
                    Application Details
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img width={150} height={100} src={barcode} alt='barcode' />
                    <Box>
                        <Typography variant="body" component='div' sx={{ fontWeight: 'bold', color: 'grey' }}>
                            Application No : {summary?.ApplicationNo}
                        </Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: 'bold' }}>
                            Application Date : {payment?.CreatedOn ? moment(payment.CreatedOn).format("DD-MMM-YYYY") : ''}
                        </Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: 'bold' }}>
                            Period From : {periodDates.minFromDate ? moment(periodDates.minFromDate).format("DD-MMM-YYYY") : ''}
                        </Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: 'bold' }}>
                            Period To : {periodDates.maxToDate ? moment(periodDates.maxToDate).format("DD-MMM-YYYY") : ''}
                        </Typography>
                <Box sx={{marginY:2}}><Button  color='primary' onClick={downloadPdf} variant='contained'>DownLoad Application Form </Button></Box>
                    </Box>
                </Box>
                <Divider />
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={4}>
                   

                        <Typography variant="h6" component='div' sx={{ fontWeight: 'bold', color: 'blue' }}>{summary?.organization_name}</Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: '600', color: 'green' }}>{summary?.organization_address} , {summary?.organization_district}</Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: '600', color: 'green' }}>{summary?.organization_state} , {summary?.organization_country}</Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: '600', color: 'green' }}>{summary?.organization_mobile}</Typography>
                        
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body" component="div" gutterBottom align="center" sx={{ fontWeight: 'bold', alignSelf: "center" }}>
                            Steps Completed
                        </Typography>
                        <HorizontalLinearStepper />
                    </Grid>
                    <Grid item xs={3}>
                 
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Registration No</Typography>
                        <Typography>{summary?.registration_details}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Exhibition/Event Name</Typography>
                        <Typography>{summary?.event_name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Exhibition/Event Purpose</Typography>
                        <Typography>{summary?.event_purpose}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Exhibition/Event Category</Typography>
                        {summary?.category === '1' ?
                            <div>        <Typography
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                            >Any Organizations / Institution
                            </Typography>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography sx={{ p: 1 }}>I {selectedOption}</Typography>
                                </Popover></div> : <Typography>{selectedOption}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
      
  
 
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Period of Exhibition / Event:</Typography>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} style={{ marginTop: 3 }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Purpose</StyledTableCell>
                                        <StyledTableCell align="center">Period</StyledTableCell>
                                        <StyledTableCell align="center">No. of Days</StyledTableCell>
                                        <StyledTableCell align="center">Area Required (Sq. mt.)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {summary?.period_of_exhibition && JSON.parse(summary?.period_of_exhibition)?.map((entry, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell>{entry.purpose}</StyledTableCell>
                                            <StyledTableCell align="center">{entry.from} to {entry.to}</StyledTableCell>
                                            <StyledTableCell align="center">{entry.days}</StyledTableCell>
                                            <StyledTableCell align="center">{entry.area}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Typography variant="body" sx={{ fontWeight: 'bold' }}>Payment Details:</Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Transaction Id</Typography>
                        <Typography>{payment?.TransId}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Application No.</Typography>
                        <Typography>{summary?.ApplicationNo}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Applicant Name</Typography>
                        <Typography>{summary?.FirstName}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Paid By</Typography>
                        <Typography>Card</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Total Paid Amount</Typography>
                        <Typography>₹ {payment?.Amount}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Paid At</Typography>
                        <Typography>{payment?.CreatedOn}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SummaryDetails;
