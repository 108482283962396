import React, { useEffect } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import FormControl from '../../../molecules/FormControl/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';
import { EventContactPerson, PmayNonPmaySelector } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';

const validationSchema = Yup.object().shape({
  contact_person_name: Yup.string().required('Contact Person Name is required'),
  contact_person_designation: Yup.string().required('Designation is required'),
  contact_person_mobile: Yup.string().required('Mobile Number is required'),
  contact_person_email: Yup.string().email('Invalid email').required('Email is required'),
});

const ContactPersonDetails = ({data}) => {
 
  const { isSuccessContact,isFetchingContact } = useSelector(PmayNonPmaySelector)


    const dispatch = useDispatch();
    useEffect(() => {
      if (isSuccessContact) {
          dispatch(RegistrationStepperSave("6"))
      }
  }, [isSuccessContact]);
  const initialValues = {
    contact_person_name:data?.contact_person_name ||'',
    contact_person_designation: data?.contact_person_designation || '',
    contact_person_mobile: data?.contact_person_mobile ||'',
    contact_person_email: data?.contact_person_email ||'',
    
  };

  const handleSubmit = (values, { setSubmitting }) => {
    // Handle form submission here, e.g., API call
    console.log(values);
    setSubmitting(false);
    dispatch(EventContactPerson(values));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
              <FormControl
                  control="input"
                  variant="outlined"
                  label="Contact Person Name"
                  placeholder="Contact Person Name"
                  name="contact_person_name"
                  type="text"
                  id="contact_person_name"
                  required
                  margin="dense"
                  helperText={<ErrorMessage name="contact_person_name" />}
                />
              
              </Grid>
              <Grid item xs={12} sm={6}>
              <FormControl
                  control="input"
                  variant="outlined"
                  label="Designation"
                  placeholder="Designation"
                  name="contact_person_designation"
                  type="text"
                  id="contact_person_designation"
                  required
                  margin="dense"
                  helperText={<ErrorMessage name="contact_person_designation" />}
                />
                
              </Grid>
              <Grid item xs={12} sm={6}>
              <FormControl
                  control="input"
                  variant="outlined"
                  label="Mobile Number"
                  placeholder="Mobile Number"
                  name="contact_person_mobile"
                  
                  type='numeric'
                  inputProps={{
                                    maxLength: 10,
                                  }}
                  id="contact_person_mobile"
                  required
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                
                 <FormControl
                  control="input"
                  variant="outlined"
                  label="Email"
                  placeholder="Email"
                  name="contact_person_email"
                  type="email"
                  id="contact_person_email"
                  required
                  margin="dense"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end" style={{ marginTop: 8 }} justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isFetchingContact}
        >
          {isFetchingContact && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
          {isFetchingContact ? 'Saving...' : 'Save & Continue'}
        </Button>
        </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ContactPersonDetails;
